<template>
  <v-row
    class="section background"
    id="Marketplace"
    align="center"
    no-gutters
    :style="'background-image: url(' + Background1 + ');' + ($vuetify.breakpoint.smAndDown ? 'padding-top: 80px; padding-bottom: 80px;' : 'padding-top: 80px')"
  >
    <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="6" style="padding-left: 20%;" align="left">
      <div class="section-title">{{ title }}</div>
      <div class="section-subtitle">{{ subtitle }}</div>
      <div class="section-body">{{ body }}</div>
    </v-col>
    <v-col
      v-else
      cols="12"
      style="padding-right: 15%; padding-left: 15%; height: calc(100vw * 0.7 * 926 / 1000);"
    >
      <v-img :src="Marketplace" :aspect-ratio="1000/926" />
    </v-col>

    <v-col
      v-if="$vuetify.breakpoint.mdAndUp"
      cols="6"
      style="padding-right: 10%; height: calc(100vw * 0.5 * 0.9 * 926 / 1000); "
    >
      <v-img :src="Marketplace" :aspect-ratio="1000/926" />
    </v-col>
    <v-col v-else cols="12" class="mt-4" style="padding-left: 10%; padding-right: 10%" align="left">
      <div class="section-title-mobile">{{ title }}</div>
      <div class="section-subtitle-mobile">{{ subtitle }}</div>
      <div class="section-body-mobile">{{ body }}</div>
    </v-col>
  </v-row>
</template>

<script>
import Blob from "@/assets/blob_purple.png";
import Marketplace from "@/assets/marketplace.png";
import Background1 from "@/assets/4_Marketplace.png";

export default {
  name: "Marketplace",

  data: () => ({
    Background1: Background1,
    Blob: Blob,
    Marketplace: Marketplace,
    title: "Web3.0",
    subtitle: "Marketplace",
    body:
      "Buy, Sell and Trade all NFTs for Momentibles Metaverse.\nOnboard with crypto and fiat (credit/debit card)\nUltra-Rare NFT series only with MNM tokens"
  })
};
</script>

<style scoped>
.background {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
</style>