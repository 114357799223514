<template>
  <v-row
    class="section background"
    no-gutters
    id="Momentibles"
    align="center"
    justify="center"
    :style="'background-image: url(' + Background1 + ')' + ($vuetify.breakpoint.mdAndUp ? ', url(' + Background2 + ');' : ';') + ($vuetify.breakpoint.smAndDown ? 'padding-top: 80px; padding-bottom: 80px;' : '')"
  >
    <v-col
      v-if="$vuetify.breakpoint.mdAndUp"
      cols="12"
      align="center"
      style="padding-left: 25%; padding-right: 25%;"
    >
      <div class="section-title" style="text-align: center">{{ title }}</div>
      <div class="section-subtitle" style="text-align: center">{{ subtitle }}</div>
      <div class="section-body mb-8">{{ body }}</div>
      <v-btn
        class="mt-8"
        color="primary"
        depressed
        style="height: 65px; width: 240px; font-size: 24px; font-weight: bold; letter-spacing: 0.1px;"
        @click="joinUs()"
      >Join Us</v-btn>
    </v-col>
    <v-col v-else cols="12" align="center" style="padding-left: 10%; padding-right: 10%;">
      <div class="section-title-mobile">{{ title }}</div>
      <div class="section-subtitle-mobile">{{ subtitle }}</div>
      <div class="section-body-mobile mb-8">{{ body }}</div>
      <v-btn
        class="mt-8"
        color="primary"
        depressed
        style="height: 65px; width: 240px; font-size: 24px; font-weight: bold; letter-spacing: 0.1px;"
        @click="joinUs()"
      >Join Us</v-btn>
    </v-col>
  </v-row>
</template>

<script>
import Background1 from "@/assets/JoinMomentibles_Bg_Waves.png";
import Background2 from "@/assets/JoinMomentibles_Blueshapes.png";

export default {
  name: "Momentibles",

  data: () => ({
    Background1: Background1,
    Background2: Background2,
    title: "Join the Metaverse of",
    subtitle: "Momentibles",
    body:
      "Momentibles is the first-ever entertainment metaverse created to empower fans and artists by bridging them and creating unforgettable moments together. Fans can build, create and monetize from experiences within and Artists can create, organize and interact with fans across the globe. Our mission is simple, “to bring all fans into the open metaverse”"
  }),

  methods: {
    joinUs() {
      document.getElementById("JoinUs").scrollIntoView({});
    }
  }
};
</script>

<style scoped>
.background {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left;
}
</style>