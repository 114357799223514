<template>
  <v-app-bar id="app-bar" class="px-8" app :color="backgroundColor" flat height="120">
    <div class="display-2 font-weight-light" style="color: #fff">
      <v-img :aspect-ratio="2157/244" :width="160" :src="Logo" />
    </div>
    <v-spacer />
    <div v-if="$vuetify.breakpoint.lgAndUp">
      <v-btn dark text @click="scrollTo('NFT')">NFT</v-btn>
      <v-btn dark text @click="scrollTo('Utilities')">Utilities</v-btn>
      <v-btn dark text @click="scrollTo('Marketplace')">Marketplace</v-btn>
      <v-btn dark text @click="scrollTo('Land')">Land</v-btn>
      <v-btn dark text @click="scrollTo('Metaverse')">Metaverse</v-btn>
      <v-btn dark text @click="scrollTo('Team')">Team</v-btn>
      <v-btn dark text @click="scrollTo('Partners')">Partnership</v-btn>
      <v-btn
        color="secondary"
        class="primary--text"
        style="font-weight: bold; height: 64px; width: 124px; letter-spacing: 0.2px;"
        large
        depressed
        @click="joinUs()"
      >Join Us</v-btn>
    </div>
    <v-btn v-else icon color="white" small @click="setDrawer(!drawer)">
      <v-icon>fa-bars</v-icon>
    </v-btn>
    <!-- <v-menu v-else offset-y :close-on-content-click="true">
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mt-1" color="white" icon v-bind="attrs" depressed v-on="on">
          <v-icon>fa-bars</v-icon>
        </v-btn>
      </template>
      <v-list color="white">
        <v-list-item @click="scrollTo('NFT')">
          <v-list-item-title style="height: 20px">NFT</v-list-item-title>
        </v-list-item>
        <v-list-item @click="scrollTo('Utilities')">
          <v-list-item-title style="height: 20px">Utilities</v-list-item-title>
        </v-list-item>
        <v-list-item @click="scrollTo('Marketplace')">
          <v-list-item-title style="height: 20px">Marketplace</v-list-item-title>
        </v-list-item>
        <v-list-item @click="scrollTo('Land')">
          <v-list-item-title style="height: 20px">Land</v-list-item-title>
        </v-list-item>
        <v-list-item @click="scrollTo('Metaverse')">
          <v-list-item-title style="height: 20px">Metaverse</v-list-item-title>
        </v-list-item>
        <v-list-item @click="scrollTo('Team')">
          <v-list-item-title style="height: 20px">Team</v-list-item-title>
        </v-list-item>
        <v-list-item @click="scrollTo('Partners')">
          <v-list-item-title style="height: 20px">Partners</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>-->
  </v-app-bar>
</template>

<script>
import Logo from "@/assets/momentibles-banner.png";
import { mapState, mapMutations } from "vuex";

export default {
  name: "AppBar",

  data() {
    return { Logo: Logo, backgroundColor: "transparent" };
  },

  mounted() {
    window.onscroll = () => {
      this.changeColor();
    };
  },

  computed: {
    ...mapState(["drawer"])
  },

  watch: {
    locale() {
      window.location.reload();
    }
  },

  methods: {
    ...mapMutations({
      setDrawer: "SET_DRAWER"
    }),
    changeColor() {
      if (
        document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 100
      ) {
        this.backgroundColor = "#00000030";
      } else {
        this.backgroundColor = "transparent";
      }
    },
    joinUs() {
      document.getElementById("JoinUs").scrollIntoView({});
    },
    scrollTo(id) {
      document.getElementById(id).scrollIntoView({
        behavior: "smooth"
      });
    }
  }
};
</script>

<style scoped>
.v-btn {
  font-size: 16;
  margin-left: 10px;
  margin-right: 10px;
}
</style>
