<template>
  <v-row
    class="section background"
    id="Metaverse"
    align="center"
    justify="center"
    no-gutters
    :style="'background-image: url(' + Background1 + ');' + ($vuetify.breakpoint.smAndDown ? 'padding-top: 80px; padding-bottom: 80px;' : '')"
  >
    <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="6" style="padding-left: 20%">
      <div class="section-title">{{ title }}</div>
      <div class="section-subtitle">{{ subtitle }}</div>
      <div class="section-body">{{ body }}</div>
    </v-col>
    <v-col
      v-else
      cols="12"
      style="padding-left: 15%; padding-right: 15%; height: calc(100vw * 0.7);"
    >
      <v-img :src="Metaverse" :aspect-ratio="1" />
    </v-col>

    <v-col
      v-if="$vuetify.breakpoint.mdAndUp"
      cols="6"
      style="padding-right: 10%; height: calc(100vw * 0.5 * 0.9);"
    >
      <v-img :src="Metaverse" :aspect-ratio="1" />
    </v-col>
    <v-col v-else cols="12" class="mt-4" style="padding-left: 10%; padding-right: 10%">
      <div class="section-title-mobile">{{ title }}</div>
      <div class="section-subtitle-mobile">{{ subtitle }}</div>
      <div class="section-body-mobile">{{ body }}</div>
    </v-col>
  </v-row>
</template>

<script>
import Blob from "@/assets/blob_purple.png";
import Metaverse from "@/assets/metaverse.png";
import Background1 from "@/assets/6_Metaverse.png";

export default {
  name: "Metaverse",

  data: () => ({
    Background1: Background1,
    Blob: Blob,
    Metaverse: Metaverse,
    title: "Phase 2.0",
    subtitle: "To the Metaverse!",
    body:
      "Partnering with celebrities and fandoms, we will enter the Metaverse together and start our journey of unimaginable experiences in an infinite space of possibilities. “It’s about all the things you dream to be”"
  })
};
</script>

<style scoped>
.background {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
</style>