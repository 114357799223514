<template>
  <v-row
    class="section"
    id="Partners"
    :style="($vuetify.breakpoint.smAndDown ? 'padding-top: 80px; padding-bottom: 80px;' : '')"
  >
    <v-col cols="12" align="center">
      <span :class="$vuetify.breakpoint.mdAndUp ? 'special' : 'special-mobile'">Partners</span>
    </v-col>
    <v-col cols="12" align="center">
      <span class="section-body">To Be Announced…</span>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Partners",

  data: () => ({})
};
</script>

<style scoped>
.special {
  background: -webkit-linear-gradient(45deg, #4cace1, #c9b9db);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 218px;
  letter-spacing: 0.1px;
}

.special-mobile {
  background: -webkit-linear-gradient(45deg, #4cace1, #c9b9db);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 60px;
  letter-spacing: 0.1px;
}
</style>