<template>
  <v-row
    class="section background"
    no-gutters
    align="center"
    justify="center"
    id="NFT"
    :style="'background-image: url(' + Background1 + ');' + ($vuetify.breakpoint.smAndDown ? 'padding-top: 80px; padding-bottom: 80px;' : '')"
  >
    <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="6" style="padding-left: 20%">
      <div class="section-title">Phase 1.0</div>
      <div class="section-subtitle">NFT Asset Offering</div>
      <div
        class="section-body"
      >Genesis Mint Pass, PFPs, Highlight Moments will be released with numerous celebrities throughout 2022 and available for trade within the marketplace.</div>
    </v-col>
    <v-col
      v-else
      cols="12"
      style="padding-left: 15%; padding-right: 15%; height: calc(100vw * 0.7 * 885 / 1000);"
    >
      <v-img class="image" :aspect-ratio="1000/885" :src="Blob" />
    </v-col>

    <v-col
      v-if="$vuetify.breakpoint.mdAndUp"
      cols="6"
      style="padding-right: 10%; padding-left: 10%; height: calc(100vw * 0.4 * 0.8 * 885 / 1000);"
    >
      <v-img class="image" :aspect-ratio="1000/885" :src="Blob" />
    </v-col>
    <v-col v-else cols="12" class="mt-8" style="padding-left: 10%; padding-right: 10%;">
      <div class="section-title-mobile">Phase 1.0</div>
      <div class="section-subtitle-mobile">NFT Asset Offering</div>
      <div
        class="section-body-mobile"
      >Genesis Mint Pass, PFPs, Highlight Moments will be released with numerous celebrities throughout 2022 and available for trade within the marketplace.</div>
    </v-col>
  </v-row>
</template>

<script>
import Blob from "@/assets/NFT_pp.png";
import Background1 from "@/assets/NftOffering_wave.png";

export default {
  name: "NFT",

  data: () => ({
    Background1: Background1,
    Blob: Blob
  })
};
</script>

<style scoped>
.background {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
}

.image {
  filter: drop-shadow(0px 4px 30px rgba(0, 0, 0, 0.35));
  border-radius: 39px;
}
</style>