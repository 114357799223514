<template>
  <div
    class="background"
    style="padding-top: 120px;"
    :style="
        $vuetify.breakpoint.mdAndUp ? 'background-image: linear-gradient(359.52deg, #0D0D0D 7.08%, rgba(13, 13, 13, 0) 46.57%), linear-gradient(270.04deg, rgba(0, 0, 0, 0.12) 58.67%, rgba(0, 0, 0, 0.6) 67.57%), url(' +
          BackgroundImage +
          ');' : 'background-image: linear-gradient(359.52deg, #0D0D0D 7.08%, rgba(13, 13, 13, 0) 46.57%), linear-gradient(0deg, rgba(0, 0, 0, 0.12) 65.67%, rgba(0, 0, 0, 0.6) 80.57%), url(' +
          BackgroundImage +
          ');'
      "
  >
    <v-row no-gutters style="height: 100%; padding-left: 10%; padding-right: 10%;">
      <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="6" class="pa-6">
        <div style="max-width: 440px; min-width: 440px;">
          <div class="title-1">THE 1ST</div>
          <div class="title-2">METAVERSE</div>
          <div class="title-3">OF ENTERTAINMENT</div>
          <div class="title-4">Taking fan experience to the next level</div>
          <v-card-actions class="mb-8 mt-4">
            <v-spacer />
            <v-btn icon color="white" @click="openLink('https://www.twitter.com/momentibles')">
              <v-icon>fab fa-twitter</v-icon>
            </v-btn>
            <v-btn icon color="white" @click="openLink('Https://www.discord.gg/momentibles')">
              <v-icon>fab fa-discord</v-icon>
            </v-btn>
            <v-btn icon color="white" @click="openLink('https://t.me/MomentiblesMetaverse')">
              <v-icon>fab fa-telegram</v-icon>
            </v-btn>
            <v-spacer />
          </v-card-actions>
          <v-btn
            class="ma-0"
            color="primary"
            depressed
            style="height: 65px; width: 100%; font-size: 24px; font-weight: bold;  letter-spacing: 0.1px; margin-left: 40px"
            @click="joinUs()"
          >JOIN US</v-btn>
        </div>
      </v-col>
      <v-col v-else cols="12" class="pa-0 ma-0" align="center">
        <v-row no-gutters>
          <v-col cols="12">
            <div style="max-width: 320px; min-width: 320px;">
              <span class="title-1" style="font-size: 28px; line-height: 32px;">THE 1ST</span>
              <span
                class="title-2"
                style="font-size: 28px; line-height: 32px;"
              >{{ ' ' }}METAVERSE OF ENTERTAINMENT</span>
            </div>
          </v-col>
        </v-row>
        <v-row style="height: 65vh;" align="end">
          <v-col cols="12" align="center">
            <v-btn
              class="ma-0"
              color="primary"
              depressed
              style="height: 65px; width: 200px; font-size: 24px; font-weight: bold; letter-spacing: 0.1px;"
              @click="joinUs()"
            >Join Now</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import BackgroundImage from "@/assets/coverpage-lite.jpg";

export default {
  name: "Home",

  data: () => ({
    BackgroundImage: BackgroundImage
  }),

  methods: {
    joinUs() {
      document.getElementById("JoinUs").scrollIntoView({});
    },

    openLink(link) {
      window.open(link, "_blank");
    }
  }
};
</script>

<style scoped>
.background {
  height: 110vh;
  width: 100vw;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.title-1 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 50px;
  line-height: 69px;
  /* or 139% */

  letter-spacing: 0.1px;
  text-transform: uppercase;

  text-align: left;

  color: #ff4a91;
}

.title-2 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 69px;
  line-height: 70px;
  /* or 139% */

  letter-spacing: 0.1px;
  text-transform: uppercase;

  color: #ffffff;
}

.title-3 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  /* or 139% */

  letter-spacing: 0.1px;
  text-transform: uppercase;

  color: #ffffff;
}

.title-4 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 50px;
  /* or 200% */

  letter-spacing: 0.1px;

  color: #4cace1;
}
</style>
