<template>
  <v-row
    class="section"
    id="Team"
    no-gutters
    style="padding-left: 10%; padding-right: 10%"
    :style="($vuetify.breakpoint.smAndDown ? 'padding-top: 80px; padding-bottom: 80px;' : '')"
  >
    <v-col cols="12" align="center">
      <span :class="$vuetify.breakpoint.mdAndUp ? 'special' : 'special-mobile'">Team</span>
    </v-col>
    <template v-for="member in team">
      <v-col
        v-if="$vuetify.breakpoint.mdAndUp"
        class="pa-8"
        :key="member.name"
        cols="3"
        align="center"
      >
        <v-avatar size="200px">
          <img alt="Avatar" contain :src="member.avatar" />
        </v-avatar>
        <div class="name" style="padding-top: 48px">{{ member.name }}</div>
        <div class="title">{{ member.title }}</div>
      </v-col>
      <v-col v-else :key="member.name" class="mt-8" cols="6" align="center" justify="center">
        <v-avatar size="130">
          <img alt="Avatar" contain :src="member.avatar" />
        </v-avatar>
        <div
          class="name"
          style="padding-top: 8px; font-size: 18px; text-align: center;"
        >{{ member.name }}</div>
        <div class="title" style="font-size: 18px; text-align: center;">{{ member.title }}</div>
      </v-col>
    </template>
  </v-row>
</template>

<script>
import Yong from "@/assets/yong.jpeg";
import Jun from "@/assets/jun.jpg";
import Wilson from "@/assets/wilson.jpeg";
import Ryan from "@/assets/ryan.jpg";
import Jay from "@/assets/jay.jpg";
import Jenn from "@/assets/jenn.jpg";
import Shimmy from "@/assets/shimmy.jpg";
import Xixi from "@/assets/xixi.jpg";
import Javier from "@/assets/javier.jpeg";
import Kazzit from "@/assets/kazzit.jpg";

export default {
  name: "Team",

  data: () => ({
    team: [
      { name: "Yong Baek", title: "CEO", avatar: Yong },
      { name: "Jun Qiu", title: "COO", avatar: Jun },
      { name: "Ryan Huang", title: "CTO", avatar: Ryan },
      { name: "Wilson Hyunh", title: "Creative", avatar: Wilson },
      { name: "Jay Lee", title: "Producer", avatar: Jay },
      { name: "Jenn Lee", title: "Content", avatar: Jenn },
      { name: "Tianxi Wu", title: "Research", avatar: Xixi },
      { name: "Shimmy", title: "Marketing", avatar: Shimmy },
      { name: "Javier Diaz", title: "Community", avatar: Javier },
      { name: "Kazzit Chang", title: "Developer", avatar: Kazzit }
    ]
  })
};
</script>

<style scoped>
.special {
  background: -webkit-linear-gradient(45deg, #4cace1, #c9b9db);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 218px;
  letter-spacing: 0.1px;
}

.name {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.1px;
  color: #2091f9;
}

.title {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #737373;
}

.special-mobile {
  background: -webkit-linear-gradient(45deg, #4cace1, #c9b9db);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 60px;
  letter-spacing: 0.1px;
}
</style>