<template>
  <v-row
    class="section"
    id="Utilities"
    align="center"
    no-gutters
    justify="center"
    :style="$vuetify.breakpoint.smAndDown ? 'padding-top: 80px; padding-bottom: 80px;' : ''"
  >
    <v-col
      v-if="$vuetify.breakpoint.mdAndUp"
      class="background"
      cols="6"
      style="padding-left: 10%; background-size: contain; height: calc(100vw * 0.8 * 0.9 * 0.5);"
      :style="'background-image: url(' + Background1 + ');'"
    >
      <v-img :src="Background2" :aspect-ratio="1" />
    </v-col>
    <v-col
      v-else
      cols="12"
      style="padding-left: 10%; padding-right: 10%; height: calc(100vw * 0.8);"
      :style="'background-image: url(' + Background1 + ');'"
    >
      <v-img :src="Background2" :aspect-ratio="1" />
    </v-col>
    <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="6" style="padding-right: 20%">
      <div class="section-title">{{ title }}</div>
      <div class="section-subtitle">{{ subtitle }}</div>
      <div class="section-body">{{ body }}</div>
    </v-col>
    <v-col v-else cols="12" class="mt-4" style="padding-left: 10%; padding-right: 10%">
      <div class="section-title-mobile">{{ title }}</div>
      <div class="section-subtitle-mobile">{{ subtitle }}</div>
      <div class="section-body-mobile">{{ body }}</div>
    </v-col>
  </v-row>
</template>

<script>
import Blob from "@/assets/blob_blue.png";
import Background1 from "@/assets/3_Real-life-Utilities_wave.png";
import Background2 from "@/assets/VIP2.png";

export default {
  name: "Utilities",

  data: () => ({
    Background1: Background1,
    Background2: Background2,
    Blob: Blob,
    title: "NFT assets",
    subtitle: "Real-life Utilities",
    body:
      "- 1 on 1 experience with the celebrity(ies)\n- VIP Meet and Greet\n- On/Offline concert experience\n- Exclusive merchandise\n"
  })
};
</script>

<style scoped>
.background {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
</style>