<template>
  <v-row
    class="section"
    id="JoinUs"
    no-gutters
    align="center"
    justify="center"
    style="background: #000; padding-bottom: 80px; padding-top: 80px;"
  >
    <v-col cols="12">
      <div class="join-title">Ready to Join?</div>
    </v-col>
    <v-col cols="12">
      <div
        class="join-subtitle"
        :style="$vuetify.breakpoint.smAndDown ? 'font-size: 16px' : ''"
      >Join the team and start a world of our own!</div>
    </v-col>
    <v-col cols="12">
      <v-card-actions class="pa-2">
        <v-spacer />
        <v-text-field
          class="ma-0"
          height="54"
          hide-details
          filled
          style="max-width: 370px; background: #fff"
          label="Email Address"
          v-model="emailAddress"
        />
        <v-btn
          class="ma-0 ml-4"
          color="primary"
          depressed
          style="height: 65px; width: 145px; font-size: 24px; font-weight: bold; letter-spacing: 0.2px;"
          @click="joinUs()"
        >Join Us</v-btn>
        <v-spacer />
      </v-card-actions>
    </v-col>
    <v-col v-if="success" cols="12">
      <div
        class="join-subtitle"
        :style="$vuetify.breakpoint.smAndDown ? 'font-size: 16px' : ''"
      >Thank you!</div>
    </v-col>
  </v-row>
</template>

<script>
import Blob from "@/assets/blob_purple.png";
import Axios from "axios";

export default {
  name: "Metaverse",

  mounted() {},

  data: () => ({
    Blob: Blob,
    success: false,
    emailAddress: null,
    title: "Phase 2.0",
    subtitle: "To the Metaverse!",
    body:
      "Partnering with celebrities and fandoms, we will enter the Metaverse together and start our journey of unimaginable experiences in an infinite space of possibilities. “It’s about all the things you dream to be”"
  }),

  methods: {
    async joinUs() {
      try {
        const path = "https://momentibles-server.herokuapp.com/";
        await Axios.post(path, {
          emailAddress: this.emailAddress
        });
        this.success = true;
      } catch (err) {
        console.log(err);
      }
    }
  }
};
</script>

<style scoped>
.join-title {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 57px;

  text-align: center;
  letter-spacing: 0.2px;

  color: #ffffff;
}

.join-subtitle {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 38px;
  /* identical to box height, or 158% */

  text-align: center;
  letter-spacing: 0.2px;

  /* Light Gray */

  color: #bdbdbd;
}
</style>