<template>
  <v-navigation-drawer
    v-if="$vuetify.breakpoint.mdAndDown"
    id="core-navigation-drawer"
    class="pa-6"
    v-model="drawer"
    :dark="false"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    mobile-break-point="960"
    app
    clipped
    width="100vh"
    v-bind="$attrs"
    style="background: #000"
  >
    <v-card-actions>
      <div class="display-2 font-weight-light" style="color: #fff">
        <v-img :aspect-ratio="2157/244" :width="160" :src="Logo" />
      </div>
      <v-spacer />
      <v-btn icon color="white" @click="drawer = false">
        <v-icon>fas fa-times</v-icon>
      </v-btn>
    </v-card-actions>

    <v-list class="mt-8 ml-8" dark nav>
      <v-list-item @click="scrollTo('NFT')">
        <v-list-item-title class="nav-item">NFT</v-list-item-title>
      </v-list-item>
      <v-list-item @click="scrollTo('Utilities')">
        <v-list-item-title class="nav-item">Utilities</v-list-item-title>
      </v-list-item>
      <v-list-item @click="scrollTo('Marketplace')">
        <v-list-item-title class="nav-item">Marketplace</v-list-item-title>
      </v-list-item>
      <v-list-item @click="scrollTo('Land')">
        <v-list-item-title class="nav-item">Land</v-list-item-title>
      </v-list-item>
      <v-list-item @click="scrollTo('Metaverse')">
        <v-list-item-title class="nav-item">Metaverse</v-list-item-title>
      </v-list-item>
      <v-list-item @click="scrollTo('Team')">
        <v-list-item-title class="nav-item">Team</v-list-item-title>
      </v-list-item>
      <v-list-item @click="scrollTo('Partners')">
        <v-list-item-title class="nav-item">Partnership</v-list-item-title>
      </v-list-item>
    </v-list>
    <v-card-actions>
      <v-spacer />
      <v-btn
        color="primary"
        class="white--text ma-8"
        style="font-weight: bold; height: 64px; width: 200px; letter-spacing: 0.2px;"
        large
        depressed
        @click="scrollTo('JoinUs')"
      >Join Now</v-btn>
      <v-spacer />
    </v-card-actions>
  </v-navigation-drawer>
</template>

<script>
import Logo from "@/assets/momentibles-banner.png";

export default {
  name: "DashboardCoreDrawer",

  props: {
    expandOnHover: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      Logo: Logo
    };
  },

  computed: {
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit("SET_DRAWER", val);
      }
    }
  },
  methods: {
    scrollTo(id) {
      document.getElementById(id).scrollIntoView(true);
      this.drawer = false;
    }
  }
};
</script>

<style scoped>
.nav-item {
  font-size: 28px;
}
</style>
