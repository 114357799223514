<template>
  <v-app>
    <app-bar />
    <menu-dialog />
    <v-main class="pa-0 ma-0">
      <v-row style="background: #0D0D0D; height: 100%">
        <v-col cols="12">
          <home />
          <Momentibles />
          <NFT />
          <Utilities />
          <Marketplace />
          <Land />
          <Metaverse />
          <Team />
          <Partners />
          <Join />
        </v-col>
      </v-row>
    </v-main>
    <footer-bar />
  </v-app>
</template>

<script>
import AppBar from './components/AppBar';
import FooterBar from './components/FooterBar';
import Home from './components/Home';
import Momentibles from './components/Momentibles';
import NFT from './components/NFT';
import Utilities from './components/Utilities';
import Marketplace from './components/Marketplace';
import Land from './components/RealEstates';
import Metaverse from './components/Metaverse';
import Team from './components/Team';
import Partners from './components/Partners';
import Join from './components/JoinUs';
import MenuDialog from './components/MenuDialog';

export default {
  name: 'App',

  components: {
    AppBar,
    MenuDialog,
    FooterBar,
    Home,
    Momentibles,
    NFT,
    Utilities,
    Marketplace,
    Land,
    Metaverse,
    Team,
    Partners,
    Join,
  },

  data: () => ({
    //
  }),
};
</script>

<style>
html,
body {
  overflow-x: hidden;
}

body {
  position: relative;
}

.v-btn {
  text-transform: none !important;
}

#app {
  font-family: 'Montserrat', 'Roboto'; /* this was it */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.section {
  width: 100%;
  padding: 160px 0px;
}

.section-image-mobile {
  padding-left: 15%;
  padding-right: 15%;
}

.section-title {
  color: #2091f9;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  text-align: left;
}

.section-title-mobile {
  color: #2091f9;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 36px;
  text-align: center;
}

.section-subtitle {
  color: #ffffff;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 57px;
  padding-top: 12px;
  text-align: left;
}

.section-subtitle-mobile {
  color: #ffffff;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 57px;
  padding-top: 12px;
  text-align: center;
}

.section-body {
  color: #b8b8b8;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 38px;
  text-align: center;
  letter-spacing: 0.2px;
  padding-top: 12px;
  white-space: pre-wrap;
  text-align: left;
}

.section-body-mobile {
  color: #b8b8b8;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 38px;
  text-align: center;
  letter-spacing: 0.2px;
  padding-top: 12px;
  white-space: pre-wrap;
  text-align: center;
}
</style>
