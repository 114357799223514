<template>
  <v-row
    class="section background"
    id="Land"
    align="center"
    justify="center"
    no-gutters
    :style="'background-image: url(' + Background1 + ');' + ($vuetify.breakpoint.smAndDown ? 'padding-top: 80px; padding-bottom: 80px;' : 'padding-top: 80px')"
  >
    <v-col
      v-if="$vuetify.breakpoint.mdAndUp"
      cols="6"
      style="padding-right: 10%; height: calc(100vw * 0.5 * 0.9 * 1443 / 1600);"
    >
      <v-img :src="Land" :aspect-ratio="1600/1443" />
    </v-col>
    <v-col v-else cols="12" style="padding-right: 10%; height: calc(100vw * 0.9 * 1443 / 1600);">
      <v-img :src="Land" :aspect-ratio="1600/1443" />
    </v-col>

    <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="6" style="padding-right: 20%">
      <div class="section-title">{{ title }}</div>
      <div class="section-subtitle">{{ subtitle }}</div>
      <div class="section-body">{{ body }}</div>
    </v-col>
    <v-col v-else cols="12" class="mt-4" style="padding-left: 10%; padding-right: 10%">
      <div class="section-title-mobile">{{ title }}</div>
      <div class="section-subtitle-mobile">{{ subtitle }}</div>
      <div class="section-body-mobile">{{ body }}</div>
    </v-col>
  </v-row>
</template>

<script>
import Blob from "@/assets/blob_blue.png";
import Land from "@/assets/land.png";
import Background1 from "@/assets/5-Landsale.png";

export default {
  name: "Land",

  data: () => ({
    Background1: Background1,
    Blob: Blob,
    Land: Land,
    title: "Own your real-estate",
    subtitle: "Land Sales",
    body:
      "Players can purchase Land within the Momentibles Metaverse. They are limited in number and can be modified to build your own games or experience. Land sales will happen in multiple phases."
  })
};
</script>

<style scoped>
.background {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;
}
</style>