<template>
  <v-footer id="dashboard-core-footer" color="#CECECE" height="120">
    <v-row no-gutters align="center" justify="center">
      <v-col cols="12" align="center">
        <div class="ma-2">© 2021 — Momentibles</div>
        <v-btn icon @click="openLink('https://www.twitter.com/momentibles')">
          <v-icon>fab fa-twitter</v-icon>
        </v-btn>
        <v-btn icon @click="openLink('Https://www.discord.gg/momentibles')">
          <v-icon>fab fa-discord</v-icon>
        </v-btn>
        <v-btn icon @click="openLink('https://t.me/MomentiblesMetaverse')">
          <v-icon>fab fa-telegram</v-icon>
        </v-btn>
        <v-btn icon @click="openLink('https://momentibles.medium.com/')">
          <v-icon>fab fa-medium</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: "DashboardCoreFooter",

  data: () => ({}),
  methods: {
    openLink(link) {
      window.open(link, "_blank");
    }
  }
};
</script>

<style></style>
